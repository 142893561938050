import {useCallback, useEffect, useRef, useState} from 'react';

import Image from 'next/image';
import Link from 'next/link';
import router from 'next/router';
import {
  FormattedMessage,
  useIntl,
  MessageDescriptor,
  defineMessage,
} from 'react-intl';
import styled from 'styled-components';

import {SocialAuthProvider, ISignInSuccess} from '@stryd/models';
import {dimensions, useScript, colors} from '@stryd/react-ui';
// import {decodeJWT} from '@stryd/util-lib';
import {capitalizeFirstLetter} from '@stryd/util-lib';

import {SupportedLocale, ValidRegionCode} from 'src/config';
import {usePageRegionData} from 'src/contexts';
import {useApi} from 'src/contexts/ApiContext';
import {redirectToPowerCenter} from 'src/features/auth/helpers';
import {colors as customColors} from 'src/styles/colors';
import {focusBoxShadow} from 'src/styles/focusBoxShadow';
import Bugsnag from 'src/utils/analytics/bugsnag';
import {loginEvent} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';
import {getAppleLocale} from 'src/utils/apple/apple-locale';
import {withBasePath} from 'src/utils/router/withBasePath';

import {ErrorContainer} from '../ErrorContainer';
import {TermsOfService} from '../TermsOfService';

import {AltPageLink} from './AltPageLink';
import {AuthPageHeading} from './AuthPageHeading';
import {EmailPasswordForm, FormSubmitResult} from './EmailPasswordForm';
import {PageContainer, PageContentContainer, pageBackground} from './layout';

const SOCIAL_BUTTON_SIZE = '48px';

const HELLO_AUTH_LOGIN_EVENT = 'auth.login';

const ForgotPasswordLink = styled.a`
  font-size: 0.9rem;
  color: ${colors.themes.dark.textHighlight};
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: ${colors.themes.dark.textHighlightHover};
  }
`;

const AccountDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  width: 100%;

  &:before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${customColors.border};

    position: absolute;
    top: 50%;
    left: 0;
  }

  span {
    z-index: 1;
    background: ${pageBackground};
    padding: 0 ${dimensions.defaultPadding};
  }
`;

const SocialSignInOptionsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > * {
    margin: 0 ${dimensions.halfPadding};
  }
`;

const SocialSignInButton = styled.button`
  position: relative;
  background: transparent;
  border: none;
  border-radius: 2px;

  width: ${SOCIAL_BUTTON_SIZE};
  height: ${SOCIAL_BUTTON_SIZE};

  ${focusBoxShadow}
`;

const genericSignInErrMessageDescriptor: MessageDescriptor = defineMessage({
  id: 'errors.genericSignIn',
  defaultMessage:
    'There was a problem signing you in. Please try again or contact us at support@stryd.com.',
});

const invalidSocialAccountMessageDescriptor: MessageDescriptor = defineMessage({
  id: 'errors.invalidSocialAuth',
  defaultMessage:
    "That {provider} account doesn't have a matching Stryd account. Try a different social account, or use an email and password to sign in.",
});

const SOCIAL_PROVIDER_IDS = {
  facebook: '581175202024696',
  google:
    '954969125250-87s6kehq9opdbijm7u52ettlgs72mt3a.apps.googleusercontent.com',
} as const;

// we need to dynamically import this because importing hello attaches it to the window.
// this behavior is not supported in SSR apps
const setupHello = async (region: ValidRegionCode, locale: SupportedLocale) => {
  const hello = (await import('hellojs')).default;

  hello.init(SOCIAL_PROVIDER_IDS, {
    redirect_uri: `/signin`,
    scope: 'email',
    display: 'page',
    state: `{"region": ${region}, "locale": ${locale}, "location": "store"}`,
  });

  window.hello = window.hello || hello;

  return window.hello;
};

const getLocalizedAppleBtnSrc = (
  lang: SupportedLocale,
  region: ValidRegionCode
) => {
  const appleLocale = getAppleLocale(lang, region);
  return `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${appleLocale}/appleid.auth.js`;
};

const waitForAppleScriptLoad = (callback: () => void) => {
  if (window.AppleID) {
    callback();
  } else {
    setTimeout(() => waitForAppleScriptLoad(callback), 50);
  }
};

export const SignInPageTemplate: React.FC = () => {
  const api = useApi();
  const intl = useIntl();
  const {locale, region} = usePageRegionData();
  const [socialAuthError, setSocialAuthError] = useState('');
  const {scriptLoaded: isAppleScriptLoaded} = useScript({
    url: getLocalizedAppleBtnSrc(locale, region),
    waitFor: waitForAppleScriptLoad,
  });

  // TODO we have to track this because the app does NOT yet block rendering until
  // stripe has loaded. stripe should live in a context, and not cause the app to rerender after loading
  const appleRequestedRef = useRef(false);

  const onAuthSuccess = useCallback(
    async (data: ISignInSuccess) => {
      const {token, id, client_id, refresh_token} = data;
      // const token = decodeJWT(jwt);

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refresh_token);
      localStorage.setItem('refreshClientId', client_id);

      api.setBearerToken(token);

      const params = new URLSearchParams(window.location.search);
      const sso = params.get('sso');
      const sig = params.get('sig');
      const strydRedirectUrl = params.get('stryd_redirect_uri');

      if (sso && sig) {
        api.auth.redirectDiscourse({sso, sig}).then((res) => {
          if (res.ok) {
            window.location.replace(res.data.redirect_url);
          } else {
            // Replace this with nicer toast
            alert('Please try and signin again.');
          }
        });
      } else if (strydRedirectUrl) {
        window.location.replace(decodeURIComponent(strydRedirectUrl));
      } else {
        redirectToPowerCenter(id);
      }
    },
    [api]
  );

  useEffect(() => {
    // handle apple sign in result
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const k = params.get('k');

    if (!code || !k || appleRequestedRef.current === true) {
      return;
    }

    appleRequestedRef.current = true;
    api.auth.signInWithApple({provider: 'apple', code, k}).then((res) => {
      if (!res.ok) {
        setSocialAuthError(
          intl.formatMessage({
            id: 'errors.genericSignIn',
            defaultMessage:
              'There was a problem signing you in. Please try again or contact us at support@stryd.com.',
          })
        );
        return;
      }

      onAuthSuccess(res.data);
    });
  }, [api.auth, intl, onAuthSuccess]);

  useEffect(() => {
    const handleSocialAuthLogin = (auth: hello.HelloJSEventArgument) => {
      setSocialAuthError('');

      if (!window.hello) {
        Bugsnag.notify('hello-js was not initialized.');
        return;
      }

      const provider = auth.network as SocialAuthProvider;

      window
        .hello(provider)
        .api('/me')
        .then((res: {id: string; name: string; email: string}) => {
          const {id, name, email} = res;
          // clear out `hello` stored credentials so that we don't auto log in users in the future
          window.localStorage.removeItem('hello');

          api.auth.signInWithSocial({id, provider}).then((loginRes) => {
            if (loginRes.ok) {
              track(loginEvent({method: provider}));
              onAuthSuccess(loginRes.data);
              return;
            }

            switch (loginRes.status) {
              // 404 means the user does not have a Stryd account with the social auth they just performed
              case 404: {
                const errMsg = intl.formatMessage(
                  invalidSocialAccountMessageDescriptor,
                  {provider: capitalizeFirstLetter(provider)}
                );
                setSocialAuthError(errMsg);
                router.push({
                  pathname: `/${region}/${locale}/signup`,
                  query: {
                    username: name,
                    email,
                    id,
                    provider,
                  },
                });
                break;
              }
              default: {
                setSocialAuthError(
                  intl.formatMessage(genericSignInErrMessageDescriptor)
                );
              }
            }
          });
        })
        .then(null, (err: any) => {
          Bugsnag.notify(err);
          setSocialAuthError(
            intl.formatMessage(genericSignInErrMessageDescriptor)
          );

          // clear out `hello` stored credentials so that we don't auto log in users in the future
          window.localStorage.removeItem('hello');
        });
    };

    setupHello(region, locale).then((hello) => {
      hello.on(HELLO_AUTH_LOGIN_EVENT, handleSocialAuthLogin);
    });

    return () => {
      if (window.hello) {
        window.hello.off(HELLO_AUTH_LOGIN_EVENT, handleSocialAuthLogin);
      }
    };
  }, [api.auth, intl, locale, onAuthSuccess, region]);

  useEffect(() => {
    if (!isAppleScriptLoaded) {
      return;
    }

    const landing_url = `https://${process.env.NEXT_PUBLIC_HOST_NAME}/${region}/${locale}/signin`;
    const state = encodeURIComponent(JSON.stringify({landing_url}));

    window.AppleID.auth.init({
      clientId: 'com.stryd.powercenter',
      scope: 'name email',
      redirectURI: `${process.env.NEXT_PUBLIC_STRYD_API_BASEURL_APPLE}/b/user/signin/apple/code`,
      state,
    });
  }, [locale, region, isAppleScriptLoaded]);

  const handleEmailSignIn = async (
    email: string,
    password: string
  ): Promise<FormSubmitResult> => {
    setSocialAuthError('');

    const res = await api.auth.signIn(email, password);

    if (!res.ok) {
      const {response: errResponse} = res.err;
      if (!errResponse) {
        return {
          ok: false,
          formError: intl.formatMessage(genericSignInErrMessageDescriptor),
        };
      }

      if (errResponse.status === 404) {
        return {
          ok: false,
          emailError: intl.formatMessage({
            id: 'errors.noAccountEmail',
            defaultMessage:
              "We couldn't find an account with this email address.",
          }),
        };
      }

      if (/password/i.test(errResponse.data.message)) {
        return {
          ok: false,
          passwordError: intl.formatMessage({
            id: 'errors.passwordIncorrect',
            defaultMessage: 'Incorrect password.',
          }),
        };
      }

      return {
        ok: false,
        formError: errResponse.data.message,
      };
    }

    track(loginEvent({method: 'email'}));

    await onAuthSuccess(res.data);
    return {ok: true};
  };

  const createSocialSignInHandler = (provider: 'google' | 'facebook') => {
    return () => {
      window.hello.login(provider);
    };
  };

  return (
    <>
      <PageContainer>
        <PageContentContainer>
          <AuthPageHeading
            heading={
              <FormattedMessage
                id="SignInPageTemplate.loginHeader"
                defaultMessage="Log in to continue"
              />
            }
            subHeading={
              // <FormattedMessage
              //   id="SignInPageTemplate.loginMessage"
              //   defaultMessage="Use an existing account to purchase your Stryd membership. The email you use will be tied to your new membership."
              // />
              null
            }
          />

          {socialAuthError && (
            <ErrorContainer role="alert">{socialAuthError}</ErrorContainer>
          )}

          <EmailPasswordForm onSubmit={handleEmailSignIn} />

          <Link href={`/${region}/${locale}/reset/password/send`}>
            <ForgotPasswordLink>
              {intl.formatMessage({
                id: 'auth.forgotPassword',
                defaultMessage: 'Forgot Password?',
              })}
            </ForgotPasswordLink>
          </Link>
          <AccountDivider>
            <span>
              <FormattedMessage
                id="SignInPageTemplate.social.header"
                defaultMessage="Or Log In With"
              />
            </span>
          </AccountDivider>

          <SocialSignInOptionsContainer>
            <SocialSignInButton onClick={createSocialSignInHandler('google')}>
              <Image
                src={withBasePath(
                  '/images/social-icons/google-sign-in-logo-only.svg'
                )}
                alt="Continue With Google"
                layout="fill"
                objectFit="contain"
              />
            </SocialSignInButton>
            <SocialSignInButton onClick={createSocialSignInHandler('facebook')}>
              <Image
                src={withBasePath(
                  '/images/social-icons/facebook-sign-in-logo-only.svg'
                )}
                alt="Continue With Facebook"
                layout="fill"
                objectFit="contain"
              />
            </SocialSignInButton>
            {/* TODO style these social buttons once there is a design */}
            <div
              id="appleid-signin"
              data-color="white"
              data-border="true"
              data-mode="logo-only"
              data-type="continue"
              data-logo-size="small"
              data-height="48"
              data-width="48"
              data-border-radius="48"
              style={{
                cursor: 'pointer',
                width: SOCIAL_BUTTON_SIZE,
                height: SOCIAL_BUTTON_SIZE,
              }}
            />
          </SocialSignInOptionsContainer>

          <TermsOfService />

          <AltPageLink
            id="sign-up-link"
            message={
              <FormattedMessage
                id="SignInPageTemplate.noAccountMessage"
                defaultMessage="Don't have an account?"
              />
            }
            href={'/signup'}
            linkText={
              <FormattedMessage
                id="actions.createAccount"
                defaultMessage="Create Account"
              />
            }
          />
        </PageContentContainer>
      </PageContainer>
    </>
  );
};
